import {
  CalendarIcon,
  ChartBarIcon,
  FolderIcon,
  InboxIcon,
  UsersIcon,
} from '@heroicons/react/outline';
import React, { Fragment } from 'react';

import CustomStyleManager from 'scenes/components/AppLayout/CustomStyleManager';

const navigation = [
  { name: 'Team', icon: UsersIcon, href: '#', count: 3, current: false },
  { name: 'Projects', icon: FolderIcon, href: '#', count: 4, current: false },
  { name: 'Calendar', icon: CalendarIcon, href: '#', current: false },
  { name: 'Documents', icon: InboxIcon, href: '#', count: 12, current: false },
  { name: 'Reports', icon: ChartBarIcon, href: '#', current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const ColorsPlayground = () => {
  return (
    <Fragment>
      <CustomStyleManager />
      <div className="min-h-[640px] mt-10 flex justify-center">
        <div className="flex flex-col w-64">
          <div className="flex-1 flex flex-col min-h-0 bg-nav-bg">
            <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
              <nav className="mt-5 flex-1 px-2 space-y-1" aria-label="Sidebar">
                {navigation.map(item => (
                  <a
                    key={item.name}
                    href={item.href}
                    className={classNames(
                      item.current
                        ? 'bg-nav-item-active-bg text-nav-item-active-text focus:bg-nav-active-focus-bg hover:text-nav-item-active-text'
                        : 'text-nav-text hover:text-nav-item-hover-text bg-nav-item-bg hover:bg-nav-item-hover-bg focus:bg-nav-item-focus-bg',
                      'group flex items-center px-2 py-2 text-sm font-semibold rounded-md'
                    )}
                  >
                    <item.icon
                      className={classNames(
                        'mr-3 flex-shrink-0 h-6 w-6',
                        item.current
                          ? 'text-nav-item-active-icon'
                          : 'text-nav-icon focus:text-nav-item-icon-focus hover:text-nav-item-icon-hover'
                      )}
                      aria-hidden="true"
                    />
                    <span className="flex-1">{item.name}</span>
                    {item.count ? (
                      <span
                        className={classNames(
                          item.current
                            ? 'bg-nav-item-active-badge-bg focus:bg-nav-active-focus-badge-bg'
                            : 'bg-nav-badge-bg focus:bg-nav-item-focus-badge-bg hover:bg-nav-item-hover-badge-bg',
                          'ml-3 inline-block py-0.5 px-3 text-xs font-semibold rounded-full text-nav-text'
                        )}
                      >
                        {item.count}
                      </span>
                    ) : null}
                  </a>
                ))}
              </nav>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ColorsPlayground;
