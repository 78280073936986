import React, { Fragment, useState } from 'react';

import { Tag, UserFilterSegment } from 'models';

import { useCurrentOrganization } from 'helpers/hooks';
import useUrlQueryParams from 'helpers/hooks/useUrlQueryParams';
import { __ } from 'helpers/i18n';

import {
  ContentContainer,
  Label,
  Level,
  LevelLeft,
  PageHeader,
  PageTitle,
  Text,
} from 'components';
import { ActiveFilters } from 'components/Filters/types';

import TagPicker from 'scenes/components/TagPicker';
import UserFilter from 'scenes/components/UserFilter';
import UserMultiFilters from 'scenes/components/UserMultiFilters';

import PlannedActionsWithStats from './PlannedActionsWithStats';

const Index = () => {
  const [filteredTagLabels, setFilteredTagLabels] = useState<Array<Tag>>([]);
  // TODO: multifilters: remove this state when is enabled for everyone
  const [userFilter, setUserFilter] = useState<UserFilterSegment | null>(null);
  const [shouldRefetchTags, setShouldRefetchTags] = useState(0);
  // TODO: multifilters: remove this FF when is enabled for everyone
  const { featureFlags } = useCurrentOrganization();
  const withUserMultiFilters = featureFlags.includes('userMultifilters');
  const { urlQueryParams } = useUrlQueryParams();

  const [userFilters, setUserFilters] = useState<ActiveFilters | ''>(
    urlQueryParams().queryParams?.userFilters || ''
  );

  return (
    <Fragment>
      <PageTitle title={__('Actions plans')} />
      <PageHeader title={__('Actions plans')} />

      <ContentContainer>
        <Text>
          {__(
            'Actions are only visible to administrators and People Review responsibles. They are not displayed to employees.'
          )}
        </Text>

        <Level additionalClassName="my-4">
          <LevelLeft additionalClassName="space-x-2">
            <Label>{__('Type')}</Label>
            <TagPicker
              key={shouldRefetchTags}
              context="planned_action"
              selectedTags={filteredTagLabels}
              onTagsChange={setFilteredTagLabels}
              additionalClassName="w-64"
              placeholder={__('Select an action type')}
              preventCreation
            />
            {withUserMultiFilters ? (
              <UserMultiFilters
                userFilters={userFilters}
                onChange={({ userFilters }) => setUserFilters(userFilters)}
              />
            ) : (
              <UserFilter segment={userFilter} onChange={setUserFilter} />
            )}
          </LevelLeft>
        </Level>
        <PlannedActionsWithStats
          tagLabels={filteredTagLabels.map(tag => tag.label)}
          refetchTags={() => setShouldRefetchTags(shouldRefetchTags + 1)}
          userFilter={userFilter}
          userFilters={userFilters}
          withUserMultiFilters={withUserMultiFilters}
        />
      </ContentContainer>
    </Fragment>
  );
};

export default Index;
