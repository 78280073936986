// Removes the organization identifier path if present
import type {
  InteractionType,
  ObjectiveKind,
  PeerFeedback,
  User,
  UserFilterSegment,
  UserReview,
} from 'models';

import store from 'config/store';

import can from 'helpers/can';
import invariant from 'helpers/invariant';
import { assert } from 'helpers/invariant';
import { userIsReviewee } from 'helpers/models/userReview';

import { getActiveUser } from 'redux/reducers';

import { ActiveFilters } from 'components/Filters/types';

import queryString from './queryString';

type ExactRouterMatch = {
  params: {
    [key: string]: string | null | undefined;
  };
  isExact: boolean;
  path: string;
  url: string;
};

export type Match<T> = ExactRouterMatch & {
  params: T;
};

export const currentPath = () => {
  const pathname = window.location.pathname;
  if (/^\/o\//.test(pathname)) {
    return '/' + pathname.split('/').slice(3).join('/');
  } else {
    return pathname;
  }
};

export const pathTo = (path: string) => {
  if (!/^\//.test(path)) {
    throw new Error("module pathTo: Expecting path to start with '/'");
  }
  return window.location.pathname.split('/').slice(0, 3).join('/') + path;
};

// Paths below DO NOT use pathTo helper
export const pathToSignin = (login?: string | null) => {
  if (!!login) {
    return `/signin?login=${encodeURIComponent(login)}`;
  } else {
    return '/signin';
  }
};

// Paths below use pathTo helper
export const pathToHome = () => pathTo('/welcome');

// Objective paths
export const pathToObjectives = () => pathToPersonalObjectives();
export const pathToObjective = (kind: ObjectiveKind, id: string) => {
  const kindPath = kind === 'personal' ? 'personal' : 'teams';
  return pathTo(`/objectives/${kindPath}/${id}`);
};
export const pathToPersonalObjectives = () => pathTo('/objectives/personal');
export const pathToUserObjectiveHistory = (userId: string) =>
  pathTo(`/user/${userId}/objectives`);

export const pathToObjectivesDashboard = () => pathTo('/objectives/dashboard');

// team objectives path
export const pathToTeamObjectives = () => pathTo('/objectives/teams');

export const pathToOrganizationObjectivePeriod = (
  objectivePeriodSlug: string
) => pathTo(`/objectives/teams/${objectivePeriodSlug}`);

export const pathToTeamObjectivesForObjectivePeriod = (
  objectivePeriodSlug: string,
  teamSlug: string
) => pathTo(`/objectives/teams/${objectivePeriodSlug}/${teamSlug}`);

// Reviews paths
export const pathToMyReviews = (type?: InteractionType | null) => {
  return pathTo(
    `/reviews/my-reviews?type=${assert(type, 'InteractionType is required')}`
  );
};

export const pathToMyTeamReviews = (type?: InteractionType | null) => {
  return pathTo(
    `/reviews/my-team-reviews?type=${assert(
      type,
      'InteractionType is required'
    )}`
  );
};

export const pathToOtherActions = (type: InteractionType | null) => {
  return pathTo(
    `/reviews/delegated-reviews?type=${assert(
      type,
      'InteractionType is required'
    )}`
  );
};

export const pathToFeedbackRequests = () =>
  pathTo('/reviews/feedback-requests');

export const pathToOldUserReview = (id: string) => pathTo(`/reviews/u/${id}`);
export const pathToOldUserReviewResults = (id: string) =>
  pathTo(`/reviews/u/${id}/results`);

export const pathToEvaluation = (id: string) => pathTo(`/evaluations/${id}`);
export const pathToNewReviewResults = (id: string) => pathTo(`/reviews/${id}`);

export const pathToTodo = (peerFeedback: PeerFeedback) => {
  return pathToEvaluation(peerFeedback.id);
};

export const pathToCurrentUserEvaluation = (review: UserReview) => {
  const activeUser = getActiveUser(store.getState());

  invariant(
    activeUser,
    'activeUser must be defined while creating pathToCurrentUserEvaluation'
  );

  const currentUserEvaluation = review.evaluations.find(
    evaluation => evaluation.reviewerId === activeUser.id
  );

  invariant(
    currentUserEvaluation,
    'currentUserEvaluation not found while creating pathToCurrentUserEvaluation for ' +
      JSON.stringify({ userReviewId: review.id, activeUserId: activeUser.id })
  );

  return pathToEvaluation(currentUserEvaluation.id);
};

export const pathToReviewResults = (review: UserReview) => {
  return pathToNewReviewResults(review.id);
};

// UserProfile paths
export const pathToUser = (id: string) => pathTo(`/user/${id}`);
export const pathToUserInformation = (id: string) =>
  pathTo(`/user/${id}/profile`);
export const pathToUserReviews = (id: string) => pathTo(`/user/${id}/reviews`);
export const pathToUserObjectives = pathToUserObjectiveHistory;
export const pathToUserSharedPeerFeedbacks = (id: string) =>
  pathTo(`/user/${id}/peer-feedback/shared`);
export const pathToUserTrainings = (id: string) =>
  pathTo(`/user/${id}/trainings`);
export const pathToUserImportedReviews = (id: string) =>
  pathTo(`/user/${id}/imported_reviews`);
export const pathToUserProfessionalBackground = (id: string) =>
  pathTo(`/user/${id}/professional-background`);
export const pathToUserCareer = (id: string) => pathTo(`/user/${id}/career`);

// MyTeam path
export const pathToMyTeam = () => pathTo('/my-team');

// People review paths
export const pathToPeopleReview = () => pathTo('/people-review');
export const pathToPeopleReviewsToComplete = () =>
  pathTo('/people-review/to-complete');
export const pathToPeopleReviewCycles = () => pathTo('/people-review/cycles');
export const pathToPeopleReviewCycleReviews = (cycleId: string) =>
  pathTo(`/people-review/cycles/${cycleId}/reviews`);
export const pathToPeopleReviewCycleReviewsWithUserFilter = (
  cycleId: string,
  userFilter:
    | { userFilter: UserFilterSegment }
    | { queryParams: { userFilters: ActiveFilters } }
) => {
  const filterQuery = queryString.stringify(userFilter);
  return `${pathToPeopleReviewCycleReviews(cycleId)}?${filterQuery}`;
};
export const pathToPeopleReviewCycleMapping = (cycleId: string) =>
  pathTo(`/people-review/cycles/${cycleId}/mapping`);
export const pathToPeopleUserReview = (peopleUserReviewId: string) =>
  pathTo(`/people-review/reviews/${peopleUserReviewId}`);
export const pathToPeopleReviewActions = () => pathTo('/people-review/actions');
export const pathToPeopleReviewDimensions = () =>
  pathTo('/people-review/dimensions');
export const pathToPeopleReviewDimension = (dimensionId: string) =>
  pathTo(`/people-review/dimensions/${dimensionId}`);

// Audits path
export const pathToAudits = () => pathTo('/audits');
export const pathToAuditReportDetails = (id: string) => pathTo(`/audits/${id}`);

// Admin paths
export const pathToAdmin = () => pathTo('/admin');
export const pathToUsers = () => pathTo('/admin/users');
export const pathToReviewCycles = (type?: InteractionType | null) =>
  pathTo(`/admin/cycles?type=${assert(type, 'InteractionType is required')}`);

export const pathToTemplates = (type?: InteractionType | null) =>
  pathTo(
    `/admin/templates?type=${assert(type, 'InteractionType is required')}`
  );

export const pathToTemplate = (id: string) => pathTo(`/admin/templates/${id}`);
export const pathToTemplateEdition = (id: string) =>
  pathTo(`/admin/templates/${id}/edit`);
export const pathToTemplatePreview = (
  id: string,
  role: 'reviewee' | 'reviewer'
) => pathTo(`/admin/templates/${id}/preview/v2/${role}`);
export const pathToSyncDetails = (id: string) => pathTo(`/admin/sync/${id}`);
export const pathToEntities = () => pathTo('/admin/entities');
export const pathToAdminRights = () => pathTo('/admin/rights');
export const pathToNotificationsSettings = () => pathTo('/admin/notifications');

// Settings
export const pathToSettings = () => pathTo('/admin/settings');
export const pathToReviewsSettings = () => pathTo('/admin/settings/reviews');
export const pathToTrainingSettings = () => pathTo('/admin/settings/training');
export const pathToThreeSixtySettings = () => pathTo('/admin/settings/360');
export const pathToObjectivesSettings = () =>
  pathTo('/admin/settings/objectives');
export const pathToENPSSettings = () => pathTo('/admin/settings/enps');

// ReviewCycle paths
export const pathToReviewCycleNew = () => pathTo('/admin/cycles/new');
export const pathToReviewCycle = (id: string) => pathTo(`/admin/cycles/${id}`);
export const pathToNewReviewCycle = (type?: string | null) =>
  pathTo(
    `/admin/cycles/new?type=${assert(type, 'InteractionType is required')}`
  );

export const pathToReviewCycleEdit = (id: string) =>
  pathTo(`/admin/cycles/${id}/edit`);
export const pathToReviewCycleTemplates = (reviewCycleId: string) =>
  pathTo(`/admin/cycles/${reviewCycleId}/templates`);
export const pathToReviewCycleTemplate = (
  reviewCycleId: string,
  templateId: string
) => pathTo(`/admin/cycles/${reviewCycleId}/templates/${templateId}`);
export const pathToReviewCycleSetup = pathToReviewCycleEdit;
export const pathToReviewCycleAnalytics = (id: string) =>
  pathTo(`/admin/cycles/${id}/analytics`);
export const pathToReviewCycleParticipants = (id: string) =>
  pathTo(`/admin/cycles/${id}/participants`);
export const pathToReviewCycleResults = (id: string) =>
  pathTo(`/admin/cycles/${id}/results`);
export const pathToReviewCycleSkills = (id: string) =>
  pathTo(`/admin/cycles/${id}/skills`);

export const pathToReviewOrEvaluation = (
  userReview: UserReview,
  activeUser: User
) => {
  if (can({ perform: 'view_results', on: userReview })) {
    return pathToReviewResults(userReview);
  }

  if (
    userIsReviewee(activeUser, userReview) &&
    userReview.hasSelfEvaluationEnabled
  ) {
    return pathToCurrentUserEvaluation(userReview);
  }

  return '';
};

// Manager review cycle paths
export const pathToManagerReviewCycle = (id: string) => pathTo(`/cycles/${id}`);
export const pathToManagerReviewCycleAnalytics = (id: string) =>
  pathTo(`/cycles/${id}/analytics`);
export const pathToManagerReviewCycleResults = (id: string) =>
  pathTo(`/cycles/${id}/results`);
export const pathToManagerReviewCycleSkills = (id: string) =>
  pathTo(`/cycles/${id}/skills`);

// ReviewCycle template paths
export const pathToReviewTemplate = (id: string) =>
  pathTo(`/admin/templates/${id}`);

export const pathToIntegrations = () => pathTo('/admin/integrations');
export const pathToTrainings = () => pathTo('/admin/trainings');
export const pathToTrainingPeriodRequests = (training_period_slug: string) =>
  pathTo(`/admin/trainings/${training_period_slug}/requests`);
export const pathToTrainingPeriodRequestsLandingPage = () =>
  pathTo(`/admin/trainings/landing`);
export const pathToTrainingPeriodCreator = () =>
  pathTo('/admin/trainings/periods/new');

// Training session paths
export const OldPathToTrainingSessionIndex = (period_slug: string) =>
  pathTo(`/admin/trainings/${period_slug}/sessions`);
export const pathToTrainingSessionDetails = (id: string) =>
  pathTo(`/admin/trainings/sessions/${id}/details`);
export const pathToTrainingSessionParticipants = (id: string) =>
  pathTo(`/admin/trainings/sessions/${id}/participants`);

// Training plan paths
export const pathToForecastTrainingPlan = (periodSlug: string) =>
  pathTo(`/admin/trainings/${periodSlug}/forecast-plan`);
export const pathToValidatedTrainingPlan = (periodSlug: string) =>
  pathTo(`/admin/trainings/${periodSlug}/validated-plan`);

// Training catalog paths
export const pathToTrainingsCatalog = () => pathTo('/trainings/catalog');
export const pathToTrainingsCatalogCourse = (id: string) =>
  pathTo(`/trainings/catalog/${id}`);

// Training organism paths
export const pathToTrainingOrganisms = () => pathTo('/trainings/organisms');

// Training course paths
export const pathToTrainingCourses = () => pathTo(`/trainings/courses`);

// eNPS paths
export const pathToENPSForm = () => pathTo('/enps');
export const pathToENPSResults = () => pathTo('/enps/results');

// Survey paths
export const pathToSurveyCampaigns = () => pathTo('/surveys/campaigns');
export const pathToSurveyConversations = () => pathTo('/surveys/conversations');
export const pathToSurveysToComplete = () => pathTo('/surveys/to-complete');
export const pathToNewSurveyCampaign = () => pathTo('/surveys/campaigns/new');
export const pathToSurveyCampaignEdit = (campaignId: string) =>
  pathTo(`/surveys/campaigns/${campaignId}/edit`);
export const pathToSurveyCampaignOverview = (
  campaignId: string,
  periodId?: string | null
) => {
  const filterQuery = queryString.stringify({ periodId });
  return pathTo(
    `/surveys/campaigns/${campaignId}/overview${
      !!filterQuery ? `?${filterQuery}` : ``
    }`
  );
};

export const pathToSurveyCampaignResults = (
  campaignId: number | string,
  questionId?: number | string | null,
  periodId?: number | string | null,
  correlationSlug?: string | null
) => {
  const filterQuery = queryString.stringify({
    questionId,
    periodId,
    correlationSlug,
  });
  return pathTo(
    `/surveys/campaigns/${campaignId}/results${
      !!filterQuery ? `?${filterQuery}` : ''
    }`
  );
};
export const pathToSurveyCampaignPreview = (campaignId: string) =>
  pathTo(`/surveys/campaigns/${campaignId}/preview`);
export const pathToMySurvey = (campaignId: string) =>
  pathTo(`/surveys/${campaignId}`);

// Skills and Career paths
export const pathToMatrixList = () => pathTo('/skills/matrices');
export const pathToMatrix = (id: string) => pathTo(`/skills/matrices/${id}`);
export const pathToMatrixEditor = (id: string) =>
  pathTo(`/skills/matrices/${id}/edit`);
export const pathToAreaList = () => pathTo('/skills/areas');
export const pathToArea = (id: string) => pathTo(`/skills/areas/${id}/edit`);
export const pathToAreaMatrixList = (id: string) =>
  pathTo(`/skills/areas/${id}/matrices`);
export const pathToMatrixEmployeesList = (id: string) =>
  pathTo(`/skills/matrices/${id}/employees`);
export const pathToEmployeesOverview = () => pathTo(`/skills/employees`);
export const pathToMatrixExplorer = () => pathTo('/skills/matrices/explorer');
