import { omit } from 'lodash';
import React from 'react';
import { compose } from 'redux';

import type { SelectedDimensionIds } from '..';
import type {
  DataLoaderProvidedProps,
  PaginationProps,
  WithPaginationProps,
} from 'lib/dataLoader';
import type {
  PaginatedCollection,
  PeopleUserReview,
  UserFilterSegment,
} from 'models';

import compositeKey from 'helpers/compositeKey';
import { __ } from 'helpers/i18n';
import { pathToPeopleUserReview } from 'helpers/paths';

import { withDeprecatedPagination } from 'lib/dataLoader';
import newDataLoader from 'lib/dataLoader/newDataLoader';
import { get } from 'redux/actions/api';

import { NoNullableValues } from 'types/predicates/NoNullValues';

import {
  BoxList,
  DatatableWrapper,
  FetchContainer,
  SearchBar,
} from 'components';
import { ActiveFilters } from 'components/Filters/types';

import UserAvatar from 'scenes/components/UserAvatar';
import UserFilter from 'scenes/components/UserFilter';
import UserMultiFilters from 'scenes/components/UserMultiFilters';
import ReviewEmptyState from 'scenes/components/userReview/ListEmptyState/index';

import PeopleGroupTagList from '../../components/PeopleGroupTagList';

type Props = {
  cycleId: string;
  dimensionIds: NoNullableValues<SelectedDimensionIds>;
  dimensionValues: { x: number; y: number };
  userFilter?: UserFilterSegment | null;
  userFilters?: ActiveFilters | '' | null;
} & WithPaginationProps;

type AfterPaginateProps = Props & PaginationProps;

type AfterConnectProps = AfterPaginateProps & {
  peopleUserReviewCollection: PaginatedCollection<PeopleUserReview>;
};

type AfterDataloaderProps = DataLoaderProvidedProps & AfterConnectProps;

const PeopleUserReviewList = ({
  search,
  page,
  countPerPage,
  peopleUserReviewCollection,
  userFilter,
  userFilters,
  isFetching,
  hasError,
  onSearchChange,
  previousPageLink,
  nextPageLink,
  getPreviousPage,
  getNextPage,
}: AfterDataloaderProps) => {
  const peopleUserReviews: PeopleUserReview[] = peopleUserReviewCollection
    ? peopleUserReviewCollection.items
    : [];

  return (
    // @ts-ignore TSFIXME: Make onSearchChange prop optional if renderHeader doesn't use it
    <DatatableWrapper
      collectionInfo={
        peopleUserReviewCollection
          ? omit(peopleUserReviewCollection, 'items')
          : null
      }
      totalCountRenderer={() => ''}
      search={search}
      page={page}
      countPerPage={countPerPage}
      previousPageLink={previousPageLink}
      nextPageLink={nextPageLink}
      getPreviousPage={getPreviousPage}
      getNextPage={getNextPage}
      isFetching={isFetching}
      hasError={hasError}
      renderNoResult={() => (
        <ReviewEmptyState title={__('No employee matches your search.')} />
      )}
      renderHeader={() => (
        <React.Fragment>
          <SearchBar
            value={search}
            placeholder={__('Search by name or email')}
            onChange={onSearchChange}
          />
          {userFilter && (
            <UserFilter
              segment={userFilter}
              additionalClassName="pb-0"
              onChange={() => {}}
              disabled
            />
          )}
          {userFilters && (
            <UserMultiFilters
              userFilters={userFilters}
              onChange={() => {}}
              additionalClassName="mt-3"
              disabled
            />
          )}
        </React.Fragment>
      )}
    >
      <FetchContainer
        isFetching={isFetching}
        hasError={hasError}
        loadingStyle="overlay"
        render={() => (
          <BoxList additionalClassName="people-user-review-list flex flex-col py-2 px-4 mt-3 rounded gap-2 border border-solid border-[#B2BBCB99]">
            {peopleUserReviews.map(peopleUserReview => (
              <div key={peopleUserReview.id} className="flex items-center">
                <UserAvatar
                  user={peopleUserReview.user}
                  link={pathToPeopleUserReview(peopleUserReview.id)}
                  withJobTitle
                  style={{ flex: 1 }}
                />
                <div className="flex-1">
                  <PeopleGroupTagList
                    peopleGroups={peopleUserReview.peopleGroups}
                  />
                </div>
              </div>
            ))}
          </BoxList>
        )}
      />
    </DatatableWrapper>
  );
};

export default compose<React.ComponentType<Props>>(
  withDeprecatedPagination,
  newDataLoader({
    fetch: ({
      page,
      countPerPage,
      search,
      cycleId,
      userFilter,
      userFilters,
      dimensionIds,
      dimensionValues,
    }: AfterPaginateProps) =>
      get(`/people_review_cycles/${cycleId}/reviews`, {
        page,
        countPerPage,
        search,
        filter: { not_to_do: true },
        userFilter,
        userFilters,
        dimensionIds,
        dimensionValues,
      }),
    hydrate: {
      peopleUserReviewCollection: {
        items: {
          user: {},
          peopleGroups: {},
        },
      },
    },
    cacheKey: ({
      page,
      countPerPage,
      search,
      cycleId,
      dimensionIds,
      dimensionValues,
    }: AfterPaginateProps) =>
      compositeKey({
        page,
        countPerPage,
        search,
        cycleId,
        dimensionIds,
        dimensionValues,
      }),
  })
)(PeopleUserReviewList);
