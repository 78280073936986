import { isEqual } from 'lodash';
import React, { Fragment } from 'react';

import type { TrainingSession } from 'models';

import { FormErrors, handleFormErrors } from 'helpers/api';
import can from 'helpers/can';
import compositeKey from 'helpers/compositeKey';
import { guessTimeZone } from 'helpers/date';
import { useAppDispatch } from 'helpers/hooks';
import { __ } from 'helpers/i18n';
import { navigate } from 'helpers/navigation';
import { OldPathToTrainingSessionIndex } from 'helpers/paths';

import { del, put } from 'redux/actions/api';

import {
  Box,
  Button,
  Icon,
  PullRight,
  SavingStatusLabel,
  WithSavingStatusRecorder,
} from 'components';

import openRemoveSessionModal from '../../components/openRemoveSessionModal';
import EditableFields from './EditableFields';

type Props = {
  session: TrainingSession;
};

const Details = ({ session: sessionFromProps }: Props) => {
  const [errors, setErrors] = React.useState<FormErrors>({});
  const [session, setSession] =
    React.useState<TrainingSession>(sessionFromProps);
  const dispatch = useAppDispatch();

  const updateSession = ({
    period,
    trainingCourse,
    ...session
  }: TrainingSession) =>
    dispatch(
      put(`training/sessions/${session.id}`, {
        trainingSession: {
          ...session,
          periodId: period.id,
          timezone: guessTimeZone(),
          trainingCourseId: trainingCourse ? trainingCourse.id : null,
        },
      })
    );

  const deleteTrainingSession = () =>
    dispatch(del(`training/sessions/${session.id}`));

  const onAfterDestroy = () =>
    navigate(OldPathToTrainingSessionIndex(session.period.slug));

  React.useEffect(() => {
    setSession(sessionFromProps);
  }, [sessionFromProps]);

  const persistSessionChanges = async (newSession?: TrainingSession) => {
    setErrors({});
    if (!!newSession) setSession(newSession);
    await handleFormErrors(
      () => updateSession(newSession || session),
      setErrors,
      true
    );
  };

  const hasChangesOrErrors = (newSession?: TrainingSession): boolean =>
    !isEqual(newSession || session, sessionFromProps) ||
    Object.keys(errors).length > 0;

  return (
    <Fragment>
      <Box additionalClassName="training-session-details-form">
        <PullRight style={{ marginBottom: 8 }}>
          <SavingStatusLabel
            failedText={() => __('The training session could not be updated')}
          />
        </PullRight>
        <WithSavingStatusRecorder
          fieldUid={compositeKey({
            trainingSessionId: session.id,
            type: 'training_session',
          })}
          onChange={persistSessionChanges}
          render={autoSavingOnChange => (
            <Fragment>
              <EditableFields
                session={session}
                setSession={setSession}
                onChange={(newSession?: TrainingSession) => {
                  if (hasChangesOrErrors(newSession))
                    autoSavingOnChange(newSession);
                }}
                errors={errors}
              />
            </Fragment>
          )}
        />
      </Box>
      {can({ perform: 'destroy', on: session }) && (
        <div style={{ display: 'flex', justifyContent: 'right' }}>
          <Button
            color="danger"
            onClick={() => {
              openRemoveSessionModal(
                session,
                deleteTrainingSession,
                onAfterDestroy
              );
            }}
          >
            <Icon style={{ marginRight: 8 }} name="delete" />
            {__('Delete session')}
          </Button>
        </div>
      )}
    </Fragment>
  );
};

export default Details;
