import React from 'react';
import { compose } from 'redux';

import type { DataLoaderProvidedProps } from 'lib/dataLoader';
import type {
  PaginationProps,
  WithPaginationProps,
} from 'lib/dataLoader/pagination/types';
import type { PaginatedCollection, TrainingSession } from 'models';

import compositeKey from 'helpers/compositeKey';
import { startToEndDateLabel } from 'helpers/date';
import { useAppDispatch } from 'helpers/hooks';
import { __, n__ } from 'helpers/i18n';
import invariant, { assertDefined } from 'helpers/invariant';
import { formatMoney } from 'helpers/money';
import { pathToTrainingSessionDetails } from 'helpers/paths';

import { newDataLoader, withDeprecatedPagination } from 'lib/dataLoader';
import { put } from 'redux/actions/api';
import { get } from 'redux/actions/api';

import {
  Box,
  CheckboxSwitch,
  DatatableWrapper,
  EmptyStateWithIcon,
  FetchContainer,
  Link,
  SimpleTable,
  Text,
} from 'components';

import AvatarList from 'scenes/components/AvatarList';

import CostHeader from './CostHeader';
import ForecastHeader from './ForecastHeader';
import NoSessionState from './NoSessionState';
import RowActions from './RowActions';

type Props = {
  periodSlug: string;
  isValidatedPlan: boolean;
} & WithPaginationProps;

type AfterPaginateProps = Props & PaginationProps;

type AfterDataLoaderProps = DataLoaderProvidedProps &
  AfterPaginateProps & {
    trainingSessionCollection: PaginatedCollection<TrainingSession>;
  };

const SessionTable = ({
  isValidatedPlan,
  trainingSessionCollection,
  isFetching,
  hasError,
  countPerPage,
  onFilterChange,
  filter,
  onSearchChange,
  sort,
  onSortChange,
  page,
  previousPageLink,
  nextPageLink,
  search,
  refetchData,
}: AfterDataLoaderProps) => {
  const dispatch = useAppDispatch();
  const updateSessionForecast = async (sessionId: string, forecast: boolean) =>
    dispatch(
      put(`training/sessions/${sessionId}`, { trainingSession: { forecast } })
    );
  const filters = isValidatedPlan
    ? []
    : [
        { param: 'all', label: __('All') },
        { param: 'forecast', label: __('In forecast plan') },
        {
          param: 'not_forecast',
          label: __('removedFromForecastPlan|FeminineSingular'),
        },
      ];

  return (
    <Box>
      <DatatableWrapper
        isFetching={isFetching}
        hasError={hasError}
        collectionInfo={trainingSessionCollection}
        countPerPage={countPerPage}
        onFilterChange={onFilterChange}
        filters={filters}
        filter={filter}
        onSearchChange={onSearchChange}
        onSortChange={onSortChange}
        page={page}
        search={search}
        previousPageLink={previousPageLink}
        nextPageLink={nextPageLink}
        totalCountRenderer={(count?: number | null) =>
          n__('%1 training session', '%1 training sessions', count || 0)
        }
        renderNoRecord={() => <NoSessionState />}
        renderNoResult={() => (
          <EmptyStateWithIcon
            title={__('No training sessions match your search')}
            iconName={'school'}
            inBox={false}
          />
        )}
      >
        <FetchContainer
          isFetching={isFetching}
          hasError={hasError}
          loadingStyle="overlay"
          render={() => {
            return (
              <SimpleTable
                keyFn={session => session.id}
                columns={[
                  {
                    header: __('Training session name'),
                    cell: session => (
                      <Text
                        transformation={session.name ? undefined : 'italic'}
                        weight={session.name ? 'semibold' : 'light'}
                      >
                        <Link
                          hasInheritedColor
                          to={pathToTrainingSessionDetails(session.id)}
                        >
                          {session.name || __('Untitled session')}
                        </Link>
                      </Text>
                    ),
                    activeSort: sort && sort['name'],
                    onSort: () => onSortChange && onSortChange('name'),
                  },
                  {
                    header: __('Participants'),
                    cell: session => {
                      const participants = assertDefined(
                        session.participants,
                        'Participants should be defined on session on this page'
                      );
                      return (
                        <AvatarList
                          users={participants.map(
                            participant => participant.user
                          )}
                          maxInlineUsers={4}
                          renderNoUser={() => (
                            <Text color="light" transformation="italic">
                              {__('No participants')}
                            </Text>
                          )}
                        />
                      );
                    },
                  },
                  {
                    header: __('Dates'),
                    cell: session => {
                      if (
                        session.startDate === null &&
                        session.endDate === null
                      ) {
                        return (
                          <Text color="light" transformation="italic">
                            {__('No dates')}
                          </Text>
                        );
                      }

                      return startToEndDateLabel(
                        session.startDate,
                        session.endDate,
                        {
                          fromToLabelsAlwaysVisible: true,
                        }
                      );
                    },
                    activeSort: sort && sort['start_date'],
                    onSort: () => onSortChange && onSortChange('start_date'),
                  },
                  !isValidatedPlan
                    ? {
                        header: <CostHeader />,
                        headerKey: 'cost_header_cell',
                        cell: session => {
                          invariant(
                            session.totalBudgetSpentCents !== null,
                            'Total budget spent should not be null'
                          );
                          if (session.totalBudgetSpentCents === 0) {
                            return (
                              <Text color="light" transformation="italic">
                                {__('No cost')}
                              </Text>
                            );
                          }

                          return formatMoney(
                            session.totalBudgetSpentCents,
                            session.totalBudgetSpentCurrency
                          );
                        },
                      }
                    : undefined,
                  !isValidatedPlan
                    ? {
                        header: <ForecastHeader />,
                        headerKey: 'forecast_header_cell',
                        cell: session => {
                          const forecast = session.forecast;
                          invariant(
                            forecast !== null,
                            'Forecast should not be null'
                          );

                          return (
                            <div className="inline-flex">
                              <CheckboxSwitch
                                value={forecast}
                                onChange={() =>
                                  updateSessionForecast(session.id, !forecast)
                                }
                                size="small"
                              />
                            </div>
                          );
                        },
                      }
                    : undefined,
                  {
                    header: '',
                    cell: session => (
                      <RowActions
                        session={session}
                        refetchSessions={refetchData}
                      />
                    ),
                  },
                ]}
                rows={
                  !!trainingSessionCollection
                    ? trainingSessionCollection.items
                    : []
                }
                additionalClassName="mb-2"
              />
            );
          }}
        />
      </DatatableWrapper>
    </Box>
  );
};

export default compose<React.ComponentType<Props>>(
  withDeprecatedPagination,
  newDataLoader({
    fetch: ({
      isValidatedPlan,
      page,
      countPerPage,
      search,
      filter,
      sort,
      periodSlug,
    }: AfterPaginateProps) =>
      get(`training/sessions`, {
        page,
        countPerPage,
        search,
        filter,
        sort,
        periodSlug,
        validated: isValidatedPlan,
      }),
    hydrate: {
      trainingSessionCollection: {
        items: {
          participants: {
            user: {},
            meeting: {},
          },
          abilities: {},
        },
      },
    },
    cacheKey: ({
      isValidatedPlan,
      page,
      countPerPage,
      search,
      filter,
      sort,
      periodSlug,
    }: AfterPaginateProps) =>
      compositeKey({
        isValidatedPlan,
        page,
        countPerPage,
        search,
        filter,
        sort,
        periodSlug,
      }),
  })
)(SessionTable);
