import React, { ComponentType } from 'react';
import { compose } from 'redux';

import { User, UserCollection } from 'models';

import compositeKey from 'helpers/compositeKey';
import { useAppDispatch } from 'helpers/hooks';

import { DataLoaderProvidedProps, newDataLoader } from 'lib/dataLoader';
import { WithPaginationProps } from 'lib/pagination/types';
import withPagination from 'lib/pagination/withPagination';
import { get } from 'redux/actions/api';

import { Box, BoxList, DatatableWrapper, FetchContainer } from 'components';
import { ActiveFilters } from 'components/Filters/types';

import { CreateTrainingRequestFormObject } from '../types';
import Header from './Header';
import UserPickerListItem from './UserPickerListItem';

type Props = {
  countPerPage: number;
  withUrlChange: boolean;
  trainingRequest: CreateTrainingRequestFormObject;
  setTrainingRequest: (
    trainingRequest: CreateTrainingRequestFormObject
  ) => void;
};

type AfterPaginateProps = Props & WithPaginationProps;

type AfterDataLoaderProps = AfterPaginateProps &
  DataLoaderProvidedProps & {
    userCollection: UserCollection;
  };

const TraineesChooser = ({
  trainingRequest,
  setTrainingRequest,
  page,
  countPerPage,
  setNextPageParams,
  setPreviousPageParams,
  setQueryParams,
  queryParams: { search, userFilters },
  isFetching,
  hasError,
  userCollection,
}: AfterDataLoaderProps) => {
  const dispatch = useAppDispatch();

  const getUsersWithoutPagination = async (
    search: string | null,
    userFilters: ActiveFilters | undefined
  ) =>
    dispatch(
      get('users', {
        search,
        userFilters,
        permission: 'create_training_request?',
        slim: true,
      })
    );

  if ((isFetching && !userCollection) || hasError) {
    return <FetchContainer isFetching={isFetching} hasError={hasError} />;
  }

  const toggleTrainee = (userId: string) => {
    let newTraineeIdsList = trainingRequest.traineeIds;
    if (newTraineeIdsList.has(userId)) newTraineeIdsList.delete(userId);
    else newTraineeIdsList.add(userId);
    setTrainingRequest({
      ...trainingRequest,
      traineeIds: newTraineeIdsList,
    });
  };

  const onRemoveEveryoneClick = () => {
    setTrainingRequest({
      ...trainingRequest,
      traineeIds: new Set(),
    });
  };

  const onAddEveryoneClick = async (
    search: string,
    userFilters: ActiveFilters | undefined
  ) => {
    const { response } = await getUsersWithoutPagination(search, userFilters);

    let traineeIds = trainingRequest.traineeIds;
    const newTraineeIds = response.body.data.map((user: User) => user.id);

    newTraineeIds.forEach(traineeIds.add, traineeIds);

    setTrainingRequest({
      ...trainingRequest,
      traineeIds: traineeIds,
    });
  };

  const { users, ...collectionInfo } = userCollection;

  return (
    <DatatableWrapper
      collectionInfo={collectionInfo}
      isFetching={isFetching}
      hasError={hasError}
      search={search}
      countPerPage={countPerPage}
      withSearch
      withUserMultiFilters
      userFilters={userFilters}
      page={page}
      getNextPage={setNextPageParams}
      getPreviousPage={setPreviousPageParams}
      onQueryParamsChange={setQueryParams}
      showTotalRecordCount={false}
      renderHeader={({ userFilters, onQueryParamsChange }) => (
        <Header
          search={search}
          userFilters={userFilters as ActiveFilters | undefined}
          onQueryParamsChange={onQueryParamsChange}
          participantCount={trainingRequest.traineeIds.size}
          totalRecordCount={collectionInfo.totalRecordCount}
          onRemoveEveryoneClick={onRemoveEveryoneClick}
          onAddEveryoneClick={onAddEveryoneClick}
        />
      )}
    >
      <Box isInset>
        <BoxList additionalClassName="participant-list">
          <FetchContainer
            isFetching={isFetching}
            hasError={hasError}
            loadingStyle="overlay"
            render={() =>
              users.map(user => (
                <UserPickerListItem
                  key={user.id}
                  user={user}
                  toggleTrainee={toggleTrainee}
                  isSelected={trainingRequest.traineeIds.has(user.id)}
                />
              ))
            }
          />
        </BoxList>
      </Box>
    </DatatableWrapper>
  );
};

export default compose<ComponentType<Props>>(
  withPagination,
  newDataLoader({
    fetch: ({
      page,
      countPerPage,
      queryParams: { search, userFilters },
    }: AfterPaginateProps) =>
      get(`users`, {
        page,
        countPerPage,
        search,
        userFilters,
        permission: 'create_training_request?',
      }),
    hydrate: {
      userCollection: {
        users: {},
      },
    },
    cacheKey: ({
      page,
      countPerPage,
      queryParams: { search, userFilters },
    }: AfterPaginateProps) =>
      compositeKey({ page, countPerPage, search, userFilters }),
  })
)(TraineesChooser);
