import React from 'react';

import type { UserFilterSegment } from 'models';

import compositeKey from 'helpers/compositeKey';

import { newDataLoader } from 'lib/dataLoader';
import { DataLoaderProvidedProps } from 'lib/dataLoader/newDataLoader';
import { get } from 'redux/actions/api';

import { Level } from 'components';
import { ActiveFilters } from 'components/Filters/types';

import OldPaginatedList from './OldPaginatedList';
import PaginatedList from './PaginatedList';
import PlannedActionsStats from './PlannedActionsStats';

type Props = {
  userFilter: UserFilterSegment;
  userFilters: ActiveFilters;
  tagLabels: Array<string>;
  refetchTags: () => Promise<void>;
  withUserMultiFilters: boolean;
};

type AfterDataLoaderProps = Props &
  DataLoaderProvidedProps & {
    plannedActionsStatsData: PlannedActionsStatsData;
  };

type PlannedActionsStatsData = {
  soonOverdueCount: number;
  overdueCount: number;
  totalCount: number;
};

const PlannedActionsWithStats = ({
  plannedActionsStatsData,
  isFetching,
  hasError,
  refetchData,
  refetchTags,
  userFilter,
  userFilters,
  tagLabels,
  withUserMultiFilters,
}: AfterDataLoaderProps) => (
  <React.Fragment>
    <Level>
      <PlannedActionsStats
        plannedActionsStatsData={plannedActionsStatsData}
        isFetching={isFetching}
        hasError={hasError}
      />
    </Level>

    {withUserMultiFilters ? (
      <PaginatedList
        tagLabels={tagLabels}
        parentRefetchData={() => {
          refetchTags();
          return refetchData();
        }}
        additionalQueryParams={{ userFilters }}
      />
    ) : (
      <OldPaginatedList
        paginationType="url"
        segment={userFilter}
        tagLabels={tagLabels}
        parentRefetchData={() => {
          refetchTags();
          return refetchData();
        }}
      />
    )}
  </React.Fragment>
);

export default newDataLoader({
  fetch: ({ userFilter, tagLabels, userFilters }: Props) =>
    get('planned/people_review_actions/stats', {
      userFilter,
      userFilters,
      'tagLabels[]': tagLabels,
    }),
  hydrate: {
    plannedActionsStatsData: {},
  },
  cacheKey: ({ userFilter, tagLabels, userFilters }: Props) =>
    compositeKey({ userFilter, tagLabels, userFilters }),
})(PlannedActionsWithStats);
