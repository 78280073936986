import React, { Fragment } from 'react';

import type { TrainingSession } from 'models';

import { __, n__ } from 'helpers/i18n';
import confirmAsync from 'helpers/react/confirmAsync';

import { Icon, StrictlySanitizedHtml, Text } from 'components';

const openRemoveSessionModal = (
  session: TrainingSession,
  deleteSession: () => Promise<void>,
  onAfterDestroy: () => void
) => {
  const participants = session.participants;
  const shouldCancelMeeting =
    !!participants &&
    participants.some(
      participant => !!participant.meeting && !participant.meeting.canceledAt
    );
  const removeRequestsWarning = (
    <StrictlySanitizedHtml
      html={n__(
        'The training request linked to this session will be visible in the "Training requests" tab but <b>the related costs and funding sources will be deleted,</b> having an impact on the budget.',
        'The training requests linked to this session will be visible in the "Training requests" tab but <b>the related costs and funding sources will be deleted,</b> having an impact on the budget.',
        !!participants ? participants.length : 0
      )}
    />
  );

  return confirmAsync(
    __('Confirm deletion'),
    <Fragment>
      <p>
        {__('This action will remove the training session')}
        {session.name ? ` ${session.name}.` : '.'}
      </p>
      {!!participants &&
        participants.length > 0 &&
        (shouldCancelMeeting ? (
          <ul style={{ listStyle: 'disc', padding: 24, paddingRight: 0 }}>
            <li>{removeRequestsWarning}</li>
            <li>
              {n__(
                'An email will be sent to the participant to cancel the calendar invitation.',
                'An email will be sent to the participants to cancel the calendar invitation.',
                participants.length
              )}
            </li>
          </ul>
        ) : (
          <p style={{ padding: '24px 0px' }}>{removeRequestsWarning}</p>
        ))}
      {__('Are you sure you want to remove this training session?')}
      <div style={{ marginTop: 20 }}>
        <Text color="danger">
          <Icon style={{ marginRight: 8 }} name="warning" />
          {__('This action cannot be undone')}
        </Text>
      </div>
    </Fragment>,
    {
      isDanger: true,
      inlineButtonIcon: shouldCancelMeeting ? (
        <Icon style={{ marginRight: 5 }} name="send" />
      ) : null,
      confirmLabel: __('Delete session'),
      onConfirm: async () => {
        await deleteSession();
        onAfterDestroy();
      },
    }
  );
};

export default openRemoveSessionModal;
