import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import {
  ColorsPlayground,
  LoggedError,
  Playground,
  UncaughtError,
  Up,
  Version,
} from './index';

const Routes = () => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.path}/version`} children={Version} />
      <Route path={`${match.path}/uncaught_error`} children={UncaughtError} />
      <Route path={`${match.path}/logged_error`} children={LoggedError} />
      <Route path={`${match.path}/up`} children={Up} />
      <Route path={`${match.path}/playground`} render={() => <Playground />} />
      <Route
        path={`${match.path}/colors-playground`}
        children={ColorsPlayground}
      />
    </Switch>
  );
};

export default Routes;
