import React from 'react';

const LoggedError = () => {
  try {
    throw new Error('Test JS logged exception');
  } catch (e) {
    window.logException(e, { tags: { test: true }, extra: { ramdon: 'data' } });
  }
  return <p>logged_error</p>;
};

export default LoggedError;
