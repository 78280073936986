import { createContext } from 'react';

import {
  ActiveFilters,
  FilterSegment,
  FilterableField,
  QuickFilter,
} from './types';

type DataContextType = {
  filterableFields: Array<Array<FilterableField>>;
  fetchFilterSegments: (filter: FilterableField | null) => void;
  isFetchingSegments: boolean;
  hasSegmentsError: boolean;
  filterSegments: Array<FilterSegment> | null;
  setFilterSegments: (segments: Array<FilterSegment> | null) => void;
  disabled: boolean;
  onFiltersChange?: (newQueryParams: {
    [key: string]: ActiveFilters | '';
  }) => void;
  filters?: ActiveFilters | '' | null;
  quickFilters?: Array<QuickFilter>;
  additionalClassName?: string;
};

export const DataContext = createContext<DataContextType>(
  {} as DataContextType
);
