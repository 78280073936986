import React, { useCallback, useState } from 'react';

import { UserFilterableFieldType } from 'models/UserFilterSegment';

import { useAppDispatch, useUserFilterableFields } from 'helpers/hooks';

import { get } from 'redux/actions/api';

import { Filters } from 'components';
import { DataContext } from 'components/Filters/DataContext';
import { ActiveFilters, FilterSegment } from 'components/Filters/types';

import useAvailableQuickFilters from './useAvailableQuickFilters';

const formatFilters = (userFilterableFields: UserFilterableFieldType[]) => {
  return userFilterableFields
    .reduce(
      (acc: Array<UserFilterableFieldType[]>, field) => {
        acc[field.custom ? 1 : 0].push(field);
        return acc;
      },
      [[], []]
    )
    .filter(group => group.length > 0);
};

type Props = {
  onChange?: (newQueryParams: { [key: string]: ActiveFilters | '' }) => void;
  userFilters?: ActiveFilters | '' | null | {};
  additionalClassName?: string;
  disabled?: boolean;
};

const UserMultiFilters = ({
  onChange,
  userFilters,
  additionalClassName,
  disabled = false,
}: Props) => {
  const dispatch = useAppDispatch();

  const userFilterableFields = useUserFilterableFields();

  const filterableFields = formatFilters(userFilterableFields);

  const quickFilters = useAvailableQuickFilters();

  const [segmentsState, setSegmentsState] = useState<{
    filterSegments: Array<FilterSegment> | null;
    isFetchingSegments: boolean;
    hasSegmentsError: boolean;
  }>({
    filterSegments: null,
    isFetchingSegments: false,
    hasSegmentsError: false,
  });

  const { isFetchingSegments, hasSegmentsError, filterSegments } =
    segmentsState;

  const fetchFilterSegments = useCallback(
    filter => {
      const fetch = async () =>
        await dispatch(
          get(`filters/${filter.slug}/segments`, { filteredSource: 'user' })
        );

      return fetch()
        .then(response => {
          const filterSegments = response.response.body.data.map(
            ({ attributes: { value, label } }) => ({
              label,
              value,
            })
          );
          setSegmentsState(prevState => ({
            ...prevState,
            filterSegments: filterSegments,
            isFetchingSegments: false,
          }));
        })
        .catch(() =>
          setSegmentsState(prevState => ({
            ...prevState,
            isFetching: false,
            hasSegmentsError: true,
          }))
        );
    },
    [dispatch]
  );

  return (
    <DataContext.Provider
      value={{
        filterableFields,
        onFiltersChange: onChange,
        filters: userFilters,
        fetchFilterSegments,
        isFetchingSegments,
        hasSegmentsError,
        filterSegments,
        setFilterSegments: segments => {
          setSegmentsState(prevState => ({
            ...prevState,
            filterSegments: segments,
          }));
        },
        quickFilters,
        disabled,
        additionalClassName,
      }}
    >
      <Filters />
    </DataContext.Provider>
  );
};

export default UserMultiFilters;
