import React from 'react';

import type { ReviewCycle } from 'models';

import { useActiveUser } from 'helpers/hooks';
import { __ } from 'helpers/i18n';

import { ContentContainer } from 'components';

import Results from 'scenes/admin/reviewCycles/Manage/Results';

type Props = {
  reviewCycle: ReviewCycle;
};

const ManagerCycleResults = ({ reviewCycle }: Props) => {
  const { fullName, id } = useActiveUser();

  return (
    <ContentContainer>
      <Results
        reviewCycle={reviewCycle}
        defaultUserFilter={{
          filterLabel: __('Direct reports of'),
          filterType: 'direct_reports',
          label: fullName,
          value: id,
        }}
      />
    </ContentContainer>
  );
};

export default ManagerCycleResults;
