import React from 'react';

import type { TrainingSession } from 'models';

import can from 'helpers/can';
import { useAppDispatch } from 'helpers/hooks';
import { __ } from 'helpers/i18n';

import { del } from 'redux/actions/api';

import { HamburgerMenu, MenuItem, MenuList } from 'components';

import openRemoveSessionModal from '../../components/openRemoveSessionModal';

type Props = {
  session: TrainingSession;
  refetchSessions: () => void;
};

const RowActions = ({ session, refetchSessions }: Props) => {
  const dispatch = useAppDispatch();
  const deleteSession = async () =>
    dispatch(del(`training/sessions/${session.id}`));

  return (
    <HamburgerMenu
      disabled={!can({ perform: 'destroy', on: session })}
      disabledMessage={__(
        "You must be the creator to access the session's actions"
      )}
    >
      <MenuList>
        <MenuItem
          isDanger
          onClick={() => {
            openRemoveSessionModal(session, deleteSession, refetchSessions);
          }}
        >
          {__('Delete session')}
        </MenuItem>
      </MenuList>
    </HamburgerMenu>
  );
};

export default RowActions;
