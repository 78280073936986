import React from 'react';

import { Overlay, Title4 } from 'components';

let version: string | null = null;
try {
  version = require('../../version');
} catch (e) {
  version = 'unknown';
}

const Version = () => (
  <Overlay additionalClassName="service-version">
    <div style={{ textAlign: 'center' }}>
      <Title4>{version}</Title4>
    </div>
  </Overlay>
);

export default Version;
