import React, { Fragment } from 'react';

import { ContentContainer } from 'components/containers';

import PageHeader from '../components/PageHeader';
import EmptyState from './EmptyState';

const Courses = () => {
  return (
    <Fragment>
      <PageHeader />

      <ContentContainer>
        <EmptyState />
      </ContentContainer>
    </Fragment>
  );
};
export default Courses;
