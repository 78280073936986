import React from 'react';

import { useCurrentOrganization } from 'helpers/hooks';
import { __ } from 'helpers/i18n';

import { EmptyStateWithIcon } from 'components';

const NoSessionState = () => {
  const { featureFlags } = useCurrentOrganization();
  const trainingPlansEnabled = featureFlags.includes('trainingPlans');

  const title = trainingPlansEnabled
    ? __('There is no session in this plan yet')
    : __('No training sessions have been created yet');
  const description = trainingPlansEnabled
    ? __(
        'As soon as a training session is added to this plan, it will be displayed in this screen.'
      )
    : __(
        'As soon as a training session is created, it will be displayed in this screen.'
      );

  return (
    <EmptyStateWithIcon
      title={title}
      description={description}
      iconName={'school'}
      inBox={false}
    />
  );
};

export default NoSessionState;
