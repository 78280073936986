import React, {
  CSSProperties,
  Fragment,
  ReactNode,
  useContext,
  useState,
} from 'react';

import colors from 'styles/colors';

import breadcrumb from 'helpers/breadcrumb';
import { __ } from 'helpers/i18n';

import {
  Button,
  CircularProgress,
  Flex,
  Icon,
  ModalCard,
  ModalCardBody,
  ModalCardFooter,
  ModalCardHead,
  Notification,
  Title,
  Title4,
} from 'components';

import clapSvg from 'scenes/components/svg/clapSvg';

import { ScrollingContext } from '../../ScrollingContext';
import sentSvg from './svg/sentSvg';

type Props = {
  isActive: boolean;
  onShare: () => Promise<void>;
  onClose: () => void;
  title: string;
  subtitle?: string | null;
  description: ReactNode;
  successTitle?: string;
  successDescription: ReactNode;
  confirmLabel: string;
  warningContents?: Array<ReactNode>;
  style?: CSSProperties;
  withSignButton?: boolean;
};

const SubmitFormModal = ({
  isActive,
  onShare,
  onClose,
  title,
  subtitle,
  description,
  successTitle,
  successDescription,
  confirmLabel,
  warningContents,
  style,
  withSignButton,
}: Props) => {
  const [state, setState] = useState<'initial' | 'loading' | 'success'>(
    'initial'
  );

  const { setShouldScrollToSignSection } = useContext(ScrollingContext);
  const handleSubmit = async () => {
    setState('loading');
    try {
      await onShare();
    } catch (e) {
      setState('initial');
      throw e;
    }

    setState('success');
  };

  const handleClose = () => {
    setState('initial');
    onClose();
  };

  const handleSignClick = () => {
    setShouldScrollToSignSection(true);
    handleClose();
  };

  const renderContent = () => {
    const imageSize = 168;

    let modalTitle: ReactNode = '';
    let modalDescription: ReactNode = '';
    let modalSubtitle: ReactNode = '';
    let modalWarningContents: ReactNode[] | undefined = [];
    let svg: string | null = null;

    switch (state) {
      case 'initial':
        modalTitle = title;
        modalDescription = description;
        modalSubtitle = subtitle;
        modalWarningContents = warningContents;
        svg = sentSvg;
        break;
      case 'loading':
        modalTitle = title;
        modalDescription = description;
        modalSubtitle = subtitle;
        modalWarningContents = warningContents;
        svg = null;
        break;
      case 'success':
        modalTitle = successTitle;
        modalDescription = successDescription;
        modalSubtitle = null;
        modalWarningContents = [];
        svg = clapSvg;
        break;
      default:
        throw new Error(`Unhandled state: ${state}`);
    }

    return (
      <Flex
        style={{ marginBottom: 16 }}
        direction="column"
        verticalAlign
        horizontalAlign
      >
        {svg ? (
          <img
            alt=""
            style={{ height: imageSize, width: imageSize, borderRadius: '50%' }}
            src={`data:image/svg+xml;base64,${window.btoa(svg)}`}
          />
        ) : (
          <CircularProgress
            style={{ width: imageSize, height: imageSize }}
            borderWidth={4}
            color={colors.primaryColor}
          />
        )}
        <Flex direction="column">
          <Title4
            style={{ marginTop: 32, marginBottom: 16, textAlign: 'center' }}
          >
            {modalTitle}
          </Title4>
          {modalSubtitle && (
            <Title size={6} style={{ marginBottom: 16, textAlign: 'center' }}>
              {modalSubtitle}
            </Title>
          )}
          {modalDescription}
          {!!modalWarningContents &&
            modalWarningContents.length > 0 &&
            modalWarningContents.map((content, i) => (
              <Notification
                kind="warning"
                icon="warning"
                style={{ marginTop: 8 }}
                key={i}
              >
                <Flex direction="column">{content}</Flex>
              </Notification>
            ))}
        </Flex>
      </Flex>
    );
  };

  const renderButtons = () => {
    switch (state) {
      case 'initial':
      case 'loading':
        return (
          <Fragment>
            <Button
              color="secondary"
              disabled={state !== 'initial'}
              onClick={handleClose}
            >
              {__('Cancel')}
            </Button>
            <Button
              color="primary"
              disabled={state !== 'initial'}
              onClick={handleSubmit}
            >
              <Icon style={{ margin: '-1px 4px 0 0' }} name="send" />{' '}
              {confirmLabel}
            </Button>
          </Fragment>
        );
      case 'success':
        return (
          <div>
            <Button
              color="secondary"
              to={breadcrumb.getAnchor('user_review_list')}
            >
              {__('Close')}
            </Button>
            {withSignButton && (
              <Button color="primary" onClick={handleSignClick}>
                {__('Sign')}
              </Button>
            )}
          </div>
        );
      default:
        throw new Error(`Unhandled state: ${state}`);
    }
  };

  return (
    <ModalCard
      style={style}
      isActive={isActive}
      refreshContentOnOpening
      onClose={handleClose}
    >
      <ModalCardHead onClose={handleClose} />
      <ModalCardBody>{renderContent()}</ModalCardBody>
      <ModalCardFooter>{renderButtons()}</ModalCardFooter>
    </ModalCard>
  );
};

export default SubmitFormModal;
