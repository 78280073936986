import React from 'react';
import { compose } from 'redux';

import { PeopleUserReviewPlannedAction } from 'models';
import { PaginatedCollection } from 'models/Collection';

import compositeKey from 'helpers/compositeKey';
import { __, n__ } from 'helpers/i18n';

import { DataLoaderProvidedProps, newDataLoader } from 'lib/dataLoader';
import { WithPaginationProps } from 'lib/pagination/types';
import withPagination from 'lib/pagination/withPagination';
import { get } from 'redux/actions/api';

import { Box, DatatableWrapper, EmptyStateWithIcon } from 'components';
import { ActiveFilters } from 'components/Filters/types';

import PeopleReviewActionsWithPagination from '../components/PeopleReviewActionsWithPagination';

type Props = {
  userFilters?: ActiveFilters | undefined;
  tagLabels?: Array<string> | undefined;
  parentRefetchData?: () => Promise<any>;
  additionalQueryParams?: Record<string, string>;
};

type AfterPaginateProps = Props & WithPaginationProps;

type AfterDataLoaderProps = AfterPaginateProps &
  DataLoaderProvidedProps & {
    peopleReviewActionsCollection: PaginatedCollection<PeopleUserReviewPlannedAction>;
  };

const PaginatedList = ({
  peopleReviewActionsCollection,
  refetchData,
  parentRefetchData,
  isFetching,
  hasError,
  page,
  countPerPage,
  queryParams: { filter, search, userFilters },
  setNextPageParams,
  setPreviousPageParams,
  setQueryParams,
}: AfterDataLoaderProps) => {
  return (
    <Box additionalClassName="actions-plan">
      <DatatableWrapper
        collectionInfo={peopleReviewActionsCollection}
        isFetching={isFetching}
        hasError={hasError}
        page={page}
        countPerPage={countPerPage}
        search={search}
        filter={filter}
        getNextPage={setNextPageParams}
        getPreviousPage={setPreviousPageParams}
        onQueryParamsChange={setQueryParams}
        withSearch
        userFilters={userFilters}
        totalCountRenderer={totalCount =>
          // @ts-ignore TSFIXME: Fix strictNullChecks error
          n__('%1 action', '%1 actions', totalCount)
        }
        filters={[
          { param: 'soon_overdue', label: __('Due soon') },
          { param: 'overdue', label: __('Overdue') },
          { param: 'to_do', label: __('To do') },
          { param: 'in_progress', label: __('In progress') },
          { param: 'done', label: __('Done') },
          { param: 'all', label: __('All') },
        ]}
        renderNoRecord={() => (
          <EmptyStateWithIcon
            iconName="rule"
            title={__('No action has been created at the moment.')}
            inBox
          />
        )}
      >
        <PeopleReviewActionsWithPagination
          peopleReviewActionsCollection={peopleReviewActionsCollection}
          isFetching={isFetching}
          hasError={hasError}
          refetchData={() => {
            parentRefetchData && parentRefetchData();
            return refetchData();
          }}
        />
      </DatatableWrapper>
    </Box>
  );
};

export default compose<React.ComponentType<Props>>(
  withPagination,
  newDataLoader({
    fetch: ({
      page,
      countPerPage,
      queryParams: { search, filter, userFilters },
      tagLabels,
    }: AfterPaginateProps) =>
      get('planned/people_review_actions', {
        page,
        countPerPage,
        search,
        filter,
        userFilters,
        'tagLabels[]': tagLabels,
      }),
    hydrate: {
      peopleReviewActionsCollection: {
        items: {
          abilities: {},
          user: {},
          resource: {},
        },
      },
    },
    cacheKey: ({
      page,
      countPerPage,
      queryParams: { search, filter, userFilters },
      tagLabels,
    }: AfterPaginateProps) =>
      compositeKey({
        page,
        countPerPage,
        search,
        filter,
        userFilters,
        tagLabels,
      }),
  })
)(PaginatedList);
