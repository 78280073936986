import React, { Fragment } from 'react';

import type { TrainingPeriod } from 'models';
import type { RouteComponentProps } from 'react-router-dom';

import { useAppDispatch, useCurrentOrganization } from 'helpers/hooks';
import { __ } from 'helpers/i18n';
import invariant from 'helpers/invariant';
import { navigate } from 'helpers/navigation';
import {
  OldPathToTrainingSessionIndex,
  pathToForecastTrainingPlan,
  pathToTrainingSessionDetails,
  pathToValidatedTrainingPlan,
} from 'helpers/paths';

import { post } from 'redux/actions/api';

import { Button, ContentContainer, Icon } from 'components';

import ExportModal from 'scenes/admin/components/ExportModal';
import TrainingPeriodPicker from 'scenes/components/TrainingPeriodPicker';

import PageHeader from '../components/PageHeader';
import SessionStats from './SessionStats';
import SessionTable from './SessionTable';

type RouterProps = RouteComponentProps<{ periodSlug: string }>;

type Props = RouterProps & { isValidatedPlan: boolean };

const Plan = ({ match, isValidatedPlan }: Props) => {
  const organization = useCurrentOrganization();
  const { periodSlug } = match.params;
  const [exportModalIsActive, setExportModalIsActive] = React.useState(false);
  const dispatch = useAppDispatch();

  invariant(periodSlug, 'Training period must be defined.');

  const handleExport = async () => {
    await dispatch(post(`training/periods/${periodSlug}/sessions/export`));
    setExportModalIsActive(true);
  };

  const trainingPlansEnabled =
    organization.featureFlags.includes('trainingPlans');

  const createNewSession = async () => {
    const response = await dispatch(
      post(`training/sessions`, {
        periodSlug: periodSlug,
        validated: isValidatedPlan,
      })
    );
    const session = response.response.body.data;
    navigate(pathToTrainingSessionDetails(session.id));
  };

  return (
    <Fragment>
      <PageHeader periodSlug={periodSlug} />
      <ContentContainer>
        <div className="flex my-4 justify-between">
          <div className="w-40">
            <TrainingPeriodPicker
              renderingStyle="popover"
              currentPeriodSlug={periodSlug}
              onChange={(period: TrainingPeriod) => {
                if (!trainingPlansEnabled) {
                  navigate(OldPathToTrainingSessionIndex(period.slug));
                  return;
                }

                isValidatedPlan
                  ? navigate(pathToValidatedTrainingPlan(period.slug))
                  : navigate(pathToForecastTrainingPlan(period.slug));
              }}
            />
          </div>
          <div>
            <Button
              additionalClassName="mr-2"
              color="secondary"
              onClick={handleExport}
            >
              {__('Export sessions')}
            </Button>
            <Button color="primary" onClick={() => createNewSession()}>
              <Icon name="add" additionalClassName="mr-1" />
              {__('Create a training session')}
            </Button>
          </div>
        </div>
        <div className="my-4">
          <SessionStats
            periodSlug={periodSlug}
            isValidatedPlan={trainingPlansEnabled ? isValidatedPlan : true}
          />
        </div>
        <SessionTable
          key={`training-${
            isValidatedPlan ? 'validated' : 'forecast'
          }-plan-${periodSlug}`}
          periodSlug={periodSlug}
          paginationType="url"
          isValidatedPlan={isValidatedPlan || !trainingPlansEnabled}
          defaultPaginationParams={{ sort: { created_at: 'desc' } }}
        />
      </ContentContainer>
      {exportModalIsActive && (
        <ExportModal isActive onClose={() => setExportModalIsActive(false)} />
      )}
    </Fragment>
  );
};

export default Plan;
