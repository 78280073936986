import React from 'react';

import type { Evaluation } from 'models';

import { __ } from 'helpers/i18n';

import { Text } from 'components';

import AvatarList from 'scenes/components/AvatarList';

type Props = {
  evaluations: Array<Evaluation>;
  maxInlinePeers: number;
};

const PeerStatuses = ({ evaluations, maxInlinePeers }: Props) => {
  const peerEvaluations = evaluations.filter(
    evaluation => evaluation.reviewerRole !== 'reviewee'
  );

  return (
    <AvatarList
      users={peerEvaluations.map(e => e.reviewer)}
      statuses={peerEvaluations.map(e => e.status)}
      maxInlineUsers={maxInlinePeers}
      renderNoUser={() => (
        <Text
          color="info"
          title={__('No peer')}
          additionalClassName="is-text-overflow-ellipsis"
        >
          {__('No peer')}
        </Text>
      )}
      testClassName="test-peer"
    />
  );
};

export default PeerStatuses;
