import React, { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import queryString from 'helpers/queryString';
import { addToken } from 'helpers/url';

import { Icon, Overlay, Title5 } from 'components';

import { __ } from '../helpers/i18n';
import invariant from '../helpers/invariant';
import { targetApi } from '../lib/api/constants';

const RedirectToDownload = () => {
  const location = useLocation();
  const getNextUrl = useCallback(() => {
    return queryString.parse(location.search).url;
  }, [location.search]);

  const getNextUrlWithToken = useCallback(() => {
    const nextUrl = getNextUrl() as string;
    return addToken(nextUrl);
  }, [getNextUrl]);

  useEffect(() => {
    const nextUrl = new URL(getNextUrl() as string);
    invariant(
      nextUrl.hostname === targetApi.hostname,
      `Invalid redirect URI: ${getNextUrl()}`
    );
    window.location.replace(getNextUrlWithToken());
  }, [getNextUrl, getNextUrlWithToken]);

  return (
    <Overlay>
      <div>
        <div style={{ marginTop: '20px', display: 'flex' }}>
          <Icon
            size="large"
            style={{ marginRight: '10px' }}
            name="arrow_downward"
          />
          <div>
            <Title5>{__('Your download should start shortly')}</Title5>
            <p>
              {__(
                '%1 to download again if that is not the case',
                <a
                  href={getNextUrlWithToken()}
                  target="_blank"
                  rel="noreferrer"
                >
                  {__('Click here')}
                </a>
              )}
            </p>
          </div>
        </div>
      </div>
    </Overlay>
  );
};

export default RedirectToDownload;
