import React from 'react';

import { __ } from 'helpers/i18n';

import { EmptyStateWithIcon } from 'components';

const EmptyState = () => (
  <EmptyStateWithIcon
    iconName="school"
    title={__('Create your training organism')}
    description={__(
      'Click on the "Add an organism" button to add your first training organism.\n' +
        'To learn more about creating training organisms, visit our Help center.'
    )}
  />
);

export default EmptyState;
