import React from 'react';

import { __ } from 'helpers/i18n';

import { EmptyStateWithIcon } from 'components';

const EmptyState = () => (
  <EmptyStateWithIcon
    iconName="school"
    title={__('Create your trainings')}
    description={__(
      'Enhance your catalog by adding trainings for your employees.\n' +
        'To learn more about adding trainings, visit our Help center.'
    )}
  />
);

export default EmptyState;
