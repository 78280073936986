import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import { Redirect, Route, Switch } from 'components';

import Courses from './Courses';
import Organisms from './Organisms';

export default function Routes() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/courses`} exact component={Courses} />
      <Route path={`${path}/organisms`} exact component={Organisms} />

      <Redirect to={`${path}/courses`} />
    </Switch>
  );
}
